/* eslint-disable semi */
import React from "react"

class NavBar extends React.Component {
  render() {
    return (
      <div>
        <nav class="navbar navbar-light bg-light">
          <p>Aaron Huynh</p>
        </nav>
      </div>
    )
  }
}
export default NavBar
